@import url('https://fonts.googleapis.com/css2?family=Alata&family=Cabin:wght@600;700&family=Roboto:wght@100;300;400;700;900&display=swap');

$base-color: #c30017;
$dark-gray: #292b2c; 
$light-gray: #f0f0f0;

$xs: 400px;
$s: 500px;
$md: 768px;
$lg : 1024px; 

*{
    margin: 0; 
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

a{
    text-decoration: none;
}

.button{
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 3px;
    background: transparent;
    border: 1px solid $base-color;
    color: $base-color;
    font-weight: 300;
    text-transform: uppercase;
    margin: 5px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    a{
        text-decoration: none;
        color: $dark-gray;
    }
    &:hover{
        background: $base-color;
        color: white;
        transition: all 0.5s ease;
    }
}

.button-icon{
    margin-left: 5px;
}

.container{
    margin: 0 !important;
    width: 100% !important;
    max-width: 100vw !important;
    min-height: 80vh;
    background: $light-gray;
    padding: 30px;
    overflow-x: hidden;
    @media(max-width: $md){
        padding: 15px 5px !important;
    }
}

.row{
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 1000px;
}

.row-no-wrap{
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-flow: row nowrap;
    justify-content: center;
    max-width: 1000px;
}

.card{
    background: white;
    border: 1px solid #d3d3d3;
    box-shadow: 0 4px 2px -2px gray;
    max-width: 95vw;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Cabin', Helvetica, sans-serif;
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    font-weight: 700
}

li{
    list-style-type: none;
}

a{
    color: black;
}

.MuiTableCell-root {
    font-size: 0.75rem !important;
    @media(max-width: 400px){
        padding: 5px !important;
    }
    @media(max-width: $md){
        font-size: 0.75rem !important;
    }
}

/* HOME */

.logo-wrap{
    height: 40px;
}

.logo{
    height: 40px;
}

.hero{
    height: 400px;
    background: url('../images/bg.jpg');
    background-size: cover;
    background-position: center;
    @media(max-width:$md){
        height: 300px;
    }
}

.hero-overlay{
    height: 100%;
    width: 100%;
    background: rgba(200, 200, 200, 0.4);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
}

.heading-top{
    margin-top: 50px;
    margin-bottom: 0;
    height: 60px;
    border: 5px solid $base-color;
    border-bottom: none;
    width: 70%;
    max-width: 700px;
    @media(max-width:$md){
        margin-top: 30px;
    }
}

.main-header{
    padding: 0;
    margin: 0;
    color: $base-color;
    font-family: 'Alata';
    text-transform: uppercase;
    @media(max-width: $xs){
    font-size: calc(0.5rem + 3.5vw);
    line-height: calc(0.5rem + 3.5vw);
    }
    @media(min-width: $xs){
    font-size: calc(0.5rem + 4vw);
    line-height: calc(0.5rem + 4vw);   
    }
    @media(min-width: $s){
    font-size: calc(0.7rem + 4.5vw);
    line-height: calc(0.7rem + 4.5vw);   
    }
    @media(min-width: $md){
    font-size: calc(0.8rem + 5vw);
    line-height: calc(0.8rem + 5vw);   
    }
    @media(min-width: 1200px){
    font-size: 4.5rem;
    }
}

.heading-bottom{
    width: 70%;
    margin-top: 0;
    max-width: 700px; 
    height: 120px;
    position: relative;
    padding-top: 20px;
    border: 5px solid $base-color;
    border-top: none;
    h4{
        color: $base-color;
        font-weight: 300;
        text-align: center;
        font-size: calc(0.5rem + 1.5vw);
        @media(min-width: 1200px){
            font-size: 1.5rem;
        }
    } 
}

.button-container{
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: flex-end;
}

.pad-left{
    width: calc(50% - 70px);
}

.pad-right{
    width: calc(50% - 70px);
}

.start-button{
    margin: 0;
    justify-content: center;
    color: white;
    font-weight: 500;
    background: $base-color;
    height: 40px;
    width: 140px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.2s ease;
    &:hover{
        width: 160px;
    }
}

.how-it-works{
    font-size: 2.5rem;
    width: 20rem;
    position: relative;
    margin: 0 auto;
    &:before{
        background: url('../images/header-bg.png');
        background-size: cover;
        opacity: .2;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translate(-50%);
        display: block;
        content: "";
        width: 80%;
        height: 80px;
    }
    @media(max-width: $s){
        font-size: 2rem;
        width: 16rem;
        &:before{
            width: 70%;
            height: 60px;
            top: 0;
        }
    }
}

/* CART */

.header-row {
    border-bottom: 2px solid $light-gray;
    td{
    font-family: 'Alata';
    text-transform: uppercase;
    color: gray;
    word-wrap: break-word;
    }
}


.decrement-button{
    color: gray;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #d3d3d3;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover{
        color: $dark-gray;
    }
}

.increment-button{
    color: gray;
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #d3d3d3;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover{
        color: $dark-gray;
    }
}